.header {
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 56px;

  background: #18181b;
  border-bottom: 1px solid #3f3f46;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.header a {
  display: inline-block;
  padding: 0 1rem;
  color: #fff;
  text-decoration: none;
  height: 36px;
  line-height: 36px;
}

.header a:hover,
.header a:active {
  border-radius: 8px;
  background: #3f3f46;
}

.header a.logo {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0 1rem;
}

.logo h1 {
  padding: 0 0.5rem;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 400;
}

@media (max-width: 639px) {
  .logo h1 {
    display: none;
  }
}

.header nav {
  display: flex;
  align-items: center;
  margin: 0 1rem;
}

header nav a.active {
  color: #a8b1ff;
}
