:root {
  font-family: "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color-scheme: light dark;
  color: #444;
  background: #fafafa;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    color: #fff;
    background: #1c1c1c;
  }
}

h1 {
  font-size: 2em;
  line-height: 1.2em;
}

body {
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

#app > main {
  display: flex;
  padding-top: 56px;
  flex-grow: 1;
  margin: 1.5rem 1rem;
  max-width: 1280px;
  align-items: flex-start;
  justify-content: center;
}
